// import mdxStyle from "./mdx.module.css.js";
import * as mdxStyle from "./mdx.module.css";
import { graphql, PageProps } from "gatsby";
import { Box } from "@mui/material";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { Helmet } from "react-helmet";
import { json } from "fetchkit";
import dayjs from "dayjs";
import useInterval from "react-useinterval";
import Layout from "../layout/Layout";

type ServerData = { phone: string | null };

async function getCount(keyword) {
  const response = await fetch(
    `https://api.joy-art.cn/api/art/anfrt/nft.mk1idlist`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ keyword: keyword }),
    }
  );
  const data: {
    code: number;
    data: string[] | null;
  } = await response.json();
  if (data.data == null || data.data.length <= 0) {
    return 0;
  } else {
    return data.data.length;
  }
}

async function getMinDate() {
  const response = await fetch(
    `https://api.joy-art.cn/api/art/anfrt/nft.mk1idlist`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ keyword: "%白皮书%典藏版%" }),
    }
  );
  const data: {
    code: number;
    data: string[] | null;
  } = await response.json();
  let minDate = dayjs("2999-7-12 16:00:00");
  if (data.data == null || data.data.length <= 0) {
    return minDate;
  }

  for (let i = 0; i < data.data.length; i++) {
    const nftResponse = await fetch(
      `https://api.joy-art.cn/api/art/anfrt/nft.mk1detail`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ nftId: data.data[i] }),
      }
    );
    const nftData: {
      code: number;
      data: {
        collectionTime: String;
      } | null;
    } = await nftResponse.json();
    if (nftData == null) {
      return;
    }
    if (dayjs(nftData.data?.collectionTime) < minDate) {
      minDate = dayjs(nftData.data?.collectionTime);
    }
  }
  return minDate;
}

async function getTotalTime(privateAddress, chapter) {
  const SumTimeResponse = await fetch(
    `/api/sum-time`,
    json({
      userAddress: privateAddress,
      chapter: chapter,
    })
  );
  const SumTimeResponseData = await SumTimeResponse.json();
  console.log({ SumTimeResponseData });
  return SumTimeResponseData;
}
export default function MdxPage({
  data,
}: // serverData,
  PageProps<Queries.Query, object, object, ServerData>) {
  const [show, setShow] = React.useState(false);
  const [timeRun, setTimeRun] = React.useState(false);
  const [secondCounter, setSecondCounter] = React.useState(0);
  // const [recordId, setRecordId] = React.useState(0);
  const [timer, setTimer] = React.useState({
    hour: "00",
    minute: "00",
    second: "00",
  });
  let privateAddress: String;
  let recordId: Number;
  const order = data.mdx?.frontmatter?.order?.toString()

  React.useEffect(() => {
    (async () => {
      try {
        // local develop
        // await fetch(`https://api.joy-art.cn/api/art/anfrt/user.PasswordLogin`, {
        //   method: "POST",
        //   credentials: "include",
        //   body: JSON.stringify({ phone: "", password: " }),
        // });

        //获取用户地址
        const getUserChainAddressResponse = await fetch(
          `https://api.joy-art.cn/api/art/anfrt/AuthService.GetUserChainAddress`,
          {
            method: "POST",
            credentials: "include",
          }
        );
        if (getUserChainAddressResponse.status != 200) {
          if (getUserChainAddressResponse.status == 401) {
            // alert("请登录账号！")
            location.href = `https://www.joy-art.cn/u/sign-in?redirectTo=${location.href}`;
            throw Error("请登录账号！");
          } else {
            throw Error(getUserChainAddressResponse.statusText);
          }
        }

        const addressList: {
          data:
          | {
            Name: string;
            Address: string;
          }[]
          | null;
        } = await getUserChainAddressResponse.json();

        if (addressList.data == null || addressList.data.length <= 0) {
          //验资失败
          throw Error("查询地址失败");
        }

        for (let i = 0; i < addressList.data.length; i++) {
          if (addressList.data[i].Name == "Conflux公链") {
            privateAddress = addressList.data[i].Address;
          }
        }
        //检查用户是否持有白皮书nft


        let memoryEdition = await getCount("%白皮书%纪念版%");
        let collectionEdition = await getCount("%白皮书%典藏版%");
        let specialEdition = await getCount("%白皮书%珍藏版%");

        let memoryEdition2022 = await getCount("%中国NFT白皮书(2022纪念)%");
        let collectionEdition2022 = await getCount("%中国NFT白皮书(2022典藏%");
        let specialEdition2022 = await getCount("%中国NFT白皮书(2022珍藏)%");



        let tmpShow = false;
        if (order?.startsWith("2021")) {
          if (collectionEdition > 0) {
            const minDate: any = await getMinDate();
            if (minDate.add(14 * collectionEdition, "day") < dayjs()) {
              // throw new Error("已过期");
            } else {
              // setShow(true);
              tmpShow = true;
            }
          }
          if (memoryEdition > 0 || specialEdition > 0) {
            // setShow(true);
            tmpShow = true;
          }
        } else {
          if (memoryEdition2022 > 0 || specialEdition2022 > 0 || collectionEdition2022 > 0) {
            // setShow(true);
            tmpShow = true;
          }
        }

        if (!tmpShow) {
          throw new Error("鉴权未通过，已过期或未持有");
        }

        //添加访问记录
        const addResponse = await fetch(
          `/api/add-visit-records`,
          json({
            address: privateAddress,
            content: data.mdx?.frontmatter?.title,
            year: data.mdx?.frontmatter?.year,
          })
        );

        const adData = await addResponse.json();
        if (adData.message) {
          throw Error(adData.message);
        }

        const iniSec = await getTotalTime(
          privateAddress,
          data.mdx?.frontmatter?.order
        );
        setSecondCounter(iniSec);
        // 向服务器发起请求开始计时
        const ReadBeginResponse = await fetch(
          `/api/read-begin-init`,
          json({
            userAddress: privateAddress,
            chapter: data.mdx?.frontmatter?.order,
            year: data.mdx?.frontmatter?.year,
          })
        );
        const ReadBeginData = await ReadBeginResponse.json();
        if (ReadBeginData.id) {
          // setRecordId(123);
          recordId = ReadBeginData.id;
          console.log(ReadBeginData.id, { recordId });
          setTimeRun(true);
          setShow(true);
          console.log(timeRun);
        }
      } catch (e) {
        // alert((e as Error).message);
        alert((e as Error).message)
        console.log((e as Error).message);
      }
      document.addEventListener(
        "visibilitychange",
        async function FinishRead() {
          console.log({ recordId });

          // 禁止文字选择
          document.onselectstart = function () {
            return false;
          };
          // 禁止复制
          document.oncopy = function () {
            return false;
          };
          // 禁止剪切
          document.oncut = function () {
            return false;
          };
          if (document.visibilityState === "hidden") {
            console.log("sending end read beacon ", recordId);
            const success = navigator.sendBeacon("/api/read-end", recordId);
            setTimeRun(false);
            if (success) {
              console.log("sending end read beacon success");
            }
          } else {
            console.log("sending begin read signal");
            // 向服务器发起请求开始计时
            const NewReadBeginResponse = await fetch(
              `/api/read-begin-init`,
              json({
                userAddress: privateAddress,
                chapter: data.mdx?.frontmatter?.order,
                year: data.mdx?.frontmatter?.year,
              })
            );

            const NewReadBeginData = await NewReadBeginResponse.json();
            if (NewReadBeginData.id) {
              // setRecordId(NewReadBeginData.id);
              recordId = NewReadBeginData.id;
              const iniSec = await getTotalTime(
                privateAddress,
                data.mdx?.frontmatter?.order
              );
              setSecondCounter(iniSec);
              setTimeRun(true);
            }
          }
        }
      );
    })();
  }, []);
  useInterval(() => {
    if (!timeRun) {
      return;
    }
    setSecondCounter(secondCounter + 1);
    setTimer({
      second:
        secondCounter % 60 < 10
          ? "0" + (secondCounter % 60)
          : (secondCounter % 60) + "",
      minute:
        parseInt(secondCounter / 60) % 60 < 10
          ? "0" + (parseInt(secondCounter / 60) % 60)
          : (parseInt(secondCounter / 60) % 60) + "",
      hour:
        parseInt(secondCounter / 3600) < 10
          ? "0" + parseInt(secondCounter / 3600)
          : parseInt(secondCounter / 3600) + "",
    });
  }, 1000);

  return (
    <Layout
      backPath={`/directory/${data.mdx!.frontmatter!.year}`}
      timmer={`${timer.hour}:${timer.minute}:${timer.second}`}
      title={data.mdx!.frontmatter!.title}
    >
      <Helmet>
        <title>
          {data.mdx!.frontmatter!.title} - {data.site!.siteMetadata!.title!}
        </title>
      </Helmet>
      <Box className={mdxStyle.root} px={3} textDecoration={"none"}>
        {show ? <MDXRenderer>{data.mdx!.body}</MDXRenderer> : <div></div>}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        <Box onClick={() => {
          if (order?.slice(-2) == '00') return
          window.location.href = `/${parseInt(order || '0') - 1}`;
        }} sx={{ py: '7px', px: '14px', color: order?.slice(-2) == '00' ? '#aaa' : '#000', border: order?.slice(-2) == '00' ? '1px solid #aaa' : '1px solid black', cursor: order?.slice(-2) == '00' ? 'default' : 'pointer' }}>上一章</Box>
        <Box onClick={() => { window.location.href = `/directory/${data.mdx!.frontmatter!.year}#1`; }} sx={{ py: '7px', px: '14px', border: '1px solid', cursor: 'pointer' }}>目录</Box>
        <Box onClick={() => {
          if (order == '202209' || order == '202210') return
          window.location.href = `/${parseInt(order || '0') + 1}`;
        }} sx={{ py: '7px', px: '14px', color: order == '202209' || order == '202210' ? '#aaa' : '#000', border: order == '202209' || order == '202210' ? '1px solid #aaa' : '1px solid black', cursor: order == '202209' || order == '202210' ? 'default' : 'pointer' }}>下一章</Box>
      </Box>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        title
      }
    }

    mdx(id: { eq: $id }) {
      frontmatter {
        title
        order
        year
      }
      body
    }
  }
`;

// export async function getServerData({
//   headers,
// }: GetServerDataProps): GetServerDataReturn<ServerData> {
//   const cookie = headers.get("cookie");
//   const phone = getPhoneFromCookie(cookie);
//   if (phone !== null) {
//     return {
//       props: { phone: phone },
//     };
//   }
//   return {
//     props: { phone: null },
//   };
// }
